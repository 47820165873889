export default [
  {
    title: '',
    content:
      'Trabalhar com a Dynamic nos traz tranquilidade. A equipe atua com auto grau de profissionalismo. Os dashboards desenvolvidos para nossa operação facilitam a gestão do dia a dia. Fizemos alguns trabalhos e, certamente, faremos outros. Temos que valorizar bons prestadores e construir uma relação duradoura.',
    customerName: 'Eber Souza',
    customerTitle: 'Marketing, Produto e Operações na EcoAssist',
    customerImage: 'https://raichu-uploads.s3.amazonaws.com/logo_ecoassist-servicos-sustentaveis_f4lYYs.png'
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      'Conheci a Dynamic quando precisei de uma solução sob medida no Excel. Contactei a empresa e desde então muitos outros projetos surgiram. Uma parceria que traz solução a muitas áreas da tecnologia. Recomendo completamente os serviços.',
    customerName: 'Rafael Mengue',
    customerTitle: 'Gerente na VIP Promotora de Crédito',
    customerImage: 'https://lh4.googleusercontent.com/-A2_aqZH1nlc/AAAAAAAAAAI/AAAAAAAAAAA/OBaDi6x1APs/s44-p-k-no-ns-nd/photo.jpg'
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      'Se eu pudesse resumir a Dynamic em uma palavra ela seria, solução! Eles são especialistas em resolver problemas.',
    customerName: 'Ayrton Meira',
    customerTitle: 'Disruptec Brasil',
    customerImage: 'https://media.licdn.com/dms/image/C4E0BAQEk8P0t84lAPA/company-logo_200_200/0/1647036573513?e=2147483647&v=beta&t=2dbHotQIQDndBvyCXubFuobEoGNfxwva7jyablgvT3s'
  }
];
