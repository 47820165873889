import styled from 'styled-components';

export const Wrapper = styled.section`
  margin-top: 2rem;
  margin-bottom: 6rem;

  .swiper-slide {
    img {
      margin: 0 auto;
    }
  }
`;
